import {fetchUserAttributes, FetchUserAttributesOutput} from "aws-amplify/auth";
import {useEffect, useState} from "react";

export const useUserAttributes = () => {
  const [userAttributes, setUserAttributes] = useState<FetchUserAttributesOutput | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserAttributes().then(data => {
      setUserAttributes(data);
    }).catch(e => {
      console.error('Error fetching user attributes:', e);
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  return {userAttributes, loading};
};