import {useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query'
import {getProcessStatus} from "../api";
import {useApiClient} from "../contexts/ApiClientContext";
import {ProcessNames, ProcessStatus} from "../api/types";
import {useEffect, useRef} from "react";
import {getGeneratedDocumentsKey} from "./useDocuments";

export const getProcessStatusKey = (processName: string) => ['process_status', processName]
export const useProcessStatus = (processName: ProcessNames): UseQueryResult<ProcessStatus, Error> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  const queryKey = getProcessStatusKey(processName)

  const query = useQuery(
    {
      queryKey,
      queryFn: () => getProcessStatus(client!, processName),
      enabled: !!client
    }
  )

  const intervalId = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (query?.data && query?.data?.isRunning) {
      intervalId.current = setInterval(() => {
        query.refetch();
      }, 30000);
    } else if (intervalId.current) {
      clearInterval(intervalId.current)
      queryClient.invalidateQueries({queryKey: getGeneratedDocumentsKey(processName)})
    }

    return () => intervalId.current && clearInterval(intervalId.current);
  }, [processName, queryClient, query, query?.data?.isRunning])

  return query
}