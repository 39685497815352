export type GeneratedDocuments = {
  fileName: string,
  url: string,
  lastModified: string
}

export enum ProcessNames {
  GENERATE_PACKING_SLIPS = 'generate_packing_slips',
  GENERATE_PICKING_LIST = 'generate_picking_list',
  UPDATE_ORDER_NUMBERS = 'update_order_numbers',
  IMPORT_ORDERS = 'import_orders',
  GROUP_SHIPPING_LABELS = 'group_shipping_labels'
}

export type ProcessResultError = {
    detail?: string, source?: {
      pointer?: string
      pointer_source_column?: number
      row_index?: number
    }, code?: string
  }

export type ProcessResult = {
  status?: "success" | "error"
  ids?: string[] | number[]
  errors?: ProcessResultError[]
}

export type ProcessStatus = {
  process: ProcessNames,
  lastRun: string,
  isRunning: boolean
  result?: ProcessResult
}

export type ClientConfiguration = {
  logo: string
  processes: ProcessNames[]
}

export type UploadFileUrl = {
  url: { url: string, fields: Record<string, string> },
  object_key: string
}