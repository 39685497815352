import axios, {AxiosInstance} from "axios";
import {ClientConfiguration, GeneratedDocuments, ProcessNames, ProcessStatus, UploadFileUrl} from "./types";


export const getDocuments = (client: AxiosInstance, processName: ProcessNames) => client.get<GeneratedDocuments[]>(`documents/${processName}`).then(data => data.data)

export const triggerProcess = (client: AxiosInstance, processName: ProcessNames, payload?: Record<string, string | undefined>) => client.post('trigger_process', {
  "process": processName,
  payload
})

export const getClientConfiguration = async (client: AxiosInstance) => client.get<ClientConfiguration>('client_configuration').then(data => data.data)

export const getProcessStatus = async (client: AxiosInstance, processName: ProcessNames) => client.get<ProcessStatus>(`process_status/${processName}`).then(data => data.data)

export const uploadImportFile = async (client: AxiosInstance, file: File) => {
  try {
    const uploadFileUrlResponse = await client.post<UploadFileUrl>('get_upload_file_url', {"file_name": file.name, "file_type": "import_orders"})
    const uploadFileUrlData = uploadFileUrlResponse.data as UploadFileUrl
    const {url, object_key: objectKey} = uploadFileUrlData

    const formData = new FormData();

    Object.entries(url.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('file', file);

    const response = await axios.post(url.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 204) {
      return objectKey
    }
  } catch (error) {
    console.error('Error uploading file', error);
    throw error
  }
}