import React from 'react';
import './App.css';
import {Amplify} from 'aws-amplify';
import {
  Authenticator,
  ThemeProvider,
  Theme,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Dashboard} from "./components/Dashboard";
import {Header} from "./components/Header";
import {ApiClientProvider} from "./contexts/ApiClientContext";
import {ReactQueryProvider} from './contexts/ReactQueryProvider';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID ?? '',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
      loginWith: {
        email: true,
      }
    }
  }
});


function App() {
  const {tokens} = useTheme();
  const theme: Theme = {
    name: 'theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
            borderWidth: '0',
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral['100'],
          },
          link: {
            color: tokens.colors.neutral['100'],
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.purple['60']}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral['80'],
            _active: {
              borderColor: tokens.colors.neutral['100'],
              color: tokens.colors.purple['100'],
            },
          },
        },
      },
    },
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <View padding="xl">
          <Authenticator hideSignUp>
            {({signOut,}) => (
              <>
                <ReactQueryProvider>
                  <ApiClientProvider>
                    <Header signOut={signOut}/>

                    <main style={{paddingTop: 20}}>
                      <Dashboard/>
                    </main>
                  </ApiClientProvider>
                </ReactQueryProvider>
              </>
            )}
          </Authenticator>
        </View>
      </ThemeProvider>
    </div>
  );
}

export default App;
