import {Loader, Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import {useDocuments} from "../hooks/useDocuments";
import {ProcessNames} from "../api/types";

export type GeneratedDocumentListProps = {
  processName: ProcessNames
}

export const GeneratedDocumentList = ({processName}: GeneratedDocumentListProps) => {
  const {data: documents, isLoading: documentsLoading} = useDocuments(processName);

  return <div>
    {documentsLoading && <Loader size='large' ariaLabel="Loading..."/>}
    {documents &&
        <Table
            highlightOnHover={true}
            size="small"
            variation="striped">
            <TableHead>
                <TableRow>
                    <TableCell as="th">Generated at</TableCell>
                    <TableCell as="th">File name</TableCell>
                    <TableCell as="th"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {documents.map(packingSlip => <TableRow key={packingSlip.fileName}>
                <TableCell>{packingSlip.lastModified}</TableCell>
                <TableCell>{packingSlip.fileName}</TableCell>
                <TableCell><a href={packingSlip.url}>Download</a></TableCell>
              </TableRow>)}
            </TableBody>
        </Table>}
  </div>
}