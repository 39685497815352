import {RunProcess} from "./RunProcess";
import {Card, View} from "@aws-amplify/ui-react";
import {useProcessStatus} from "../hooks/useProcessStatus";
import {ReactNode} from "react";
import {ProcessNames, ProcessStatus} from "../api/types";

export type ProcessCardProps = {
  title: string
  processName: ProcessNames
  resultMessage?: string
  errorMessage?: string
  children?: (processStatus?: ProcessStatus) => ReactNode
}
export const ProcessCard = ({title, processName, resultMessage, errorMessage, children}: ProcessCardProps) => {
  const {data: processStatus, isLoading} = useProcessStatus(processName);

  return <Card variation="outlined">
    <View textAlign="start">
      <p style={{fontSize: 16, fontWeight: 'bold', marginTop: 0}}>{title}</p>
      <div style={{marginBottom: 20}}>
        <RunProcess processName={processName}
                    resultMessage={resultMessage}
                    errorMessage={errorMessage}
                    isLoading={processStatus?.isRunning}
                    disabled={isLoading}
        ></RunProcess>
      </div>
      {processStatus?.lastRun &&
          <p>Last run: <span style={{fontStyle: 'italic'}}>{processStatus?.lastRun}</span></p>
      }

      {children && children(processStatus)}
    </View>
  </Card>
}