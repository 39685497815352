import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {getClientConfiguration} from "../api";
import {useApiClient} from "../contexts/ApiClientContext";
import {ClientConfiguration} from "../api/types";
import {AxiosError} from "axios";

export const getClientConfigurationKey = () => ['client_configuration']
export const useClientConfiguration = (): UseQueryResult<ClientConfiguration, AxiosError> => {
  const client = useApiClient();

  const queryKey = getClientConfigurationKey()

  return useQuery(
    {
      queryKey,
      queryFn: () => getClientConfiguration(client!),
      enabled: !!client
    }
  )
}