import * as React from 'react';
import {
  Button,
  DropZone,
  Flex,
  Text,
  VisuallyHidden,
} from '@aws-amplify/ui-react';
import {ChangeEvent, Dispatch, SetStateAction, useRef, useState} from "react";
import {formatBytes} from "../utils";

const acceptedFileTypes = ['.csv'];

export type ImportFileUploadProps = {
  multiple?: boolean,
  files: File[],
  setFiles: Dispatch<SetStateAction<File[]>>
  maxFileSize?: number
}
export const ImportFileUpload = ({
                                   multiple = false,
                                   files,
                                   setFiles,
                                   maxFileSize = 3 * 1024 * 1024
                                 }: ImportFileUploadProps) => {
  const hiddenInput = useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string | undefined>()

  const validateFileSize = (files: File[]) =>
    !(maxFileSize && files.some((file) => file.size > maxFileSize))

  const onFilePickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValidationError(undefined)

    const {files} = event.target;
    if (!files || files.length === 0) {
      return;
    }

    if (maxFileSize && files && !validateFileSize(Array.from(files))) {
      setValidationError(
        `One of the uploaded files is too large, the maximum allowed file size is ${formatBytes(
          maxFileSize ?? 0
        )}`
      )
      return
    }

    setFiles(Array.from(files));
    event.target.value = ''
  };

  return (
    <>
      <DropZone
        acceptedFileTypes={acceptedFileTypes}
        onDropComplete={({acceptedFiles, rejectedFiles}) => {
          setFiles(!multiple && acceptedFiles.length ? [acceptedFiles[0]] : acceptedFiles);
        }}
      >
        <Flex direction="column" alignItems="center">
          {!!files.length ? <>
            {files.map((file) => (
              <Text key={file.name}>{file.name}</Text>
            ))}
            <Button size="small" onClick={() => setFiles([])}>
              Remove files
            </Button>
          </> : <>
            <Text fontWeight="bold">Drag and drop your csv file here or</Text>
            <Button size="small" onClick={() => hiddenInput?.current?.click()}>
              Browse
            </Button>
            <Text fontSize="0.8em">Upload a document with a max size of {formatBytes(
              maxFileSize ?? 0
            )}</Text>
            {validationError && <Text fontSize="0.8em" variation="error">{validationError}</Text>}
          </>}
        </Flex>
        <VisuallyHidden>
          <input
            type="file"
            tabIndex={-1}
            ref={hiddenInput}
            onChange={onFilePickerChange}
            multiple={multiple}
            accept={acceptedFileTypes.join(',')}
          />
        </VisuallyHidden>
      </DropZone>
    </>
  );
}