import React, {createContext, useContext, useEffect, useState} from 'react';
import axios, {AxiosInstance} from 'axios';
import {fetchAuthSession} from 'aws-amplify/auth';

interface ApiClientContextType {
  client: AxiosInstance | null
}

const ApiClientContext = createContext<ApiClientContextType>({client: null});

export const ApiClientProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [client, setClient] = useState<ApiClientContextType>({client: null});

  useEffect(() => {
    fetchAuthSession().then(authSession => {
      const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_API_GATEWAY_ID,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authSession.tokens?.idToken?.toString()}`
        },
      });
      setClient({client: axiosClient});
    }).catch(e => {
      console.error('Error creating Axios client:', e);
    });
  }, []);

  return (
    <ApiClientContext.Provider value={client}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const useApiClient = () => {
  const {client} = useContext(ApiClientContext);

  return client
};
