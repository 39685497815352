import {useMutation, useQueryClient} from "@tanstack/react-query";
import {triggerProcess} from "../api";
import {useApiClient} from "../contexts/ApiClientContext";
import {getProcessStatusKey} from "./useProcessStatus";
import {ProcessNames} from "../api/types";

export const useTriggerProcess = (processName: ProcessNames) => {
  const queryClient = useQueryClient()
  const client = useApiClient()

  return useMutation({
    mutationFn: (payload?: Record<string, string | undefined>) => triggerProcess(client!, processName, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProcessStatusKey(processName)})
    },
  })
}