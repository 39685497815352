import {
  Alert,
  Button, View
} from '@aws-amplify/ui-react';

import {useState} from 'react';
import {useApiClient} from "../contexts/ApiClientContext";
import {ProcessNames} from "../api/types";
import {useTriggerProcess} from "../hooks/useTriggerProcess";

export type RunProcessProps = {
  resultMessage?: string
  errorMessage?: string,
  processName: ProcessNames
  payload?: Record<string, string>
  preparePayload?: () => Promise<Record<string, string | undefined>>
  onSuccess?: () => void
  isLoading?: boolean
  disabled?: boolean
}
export const RunProcess = ({
                             resultMessage = 'Your request to run process has been successfully initiated.',
                             errorMessage = 'Oops! Something went wrong while sending your request. Please try again, or reach out to our support team for assistance.',
                             processName,
                             payload,
                             isLoading,
                             disabled,
                             preparePayload,
                             onSuccess
                           }: RunProcessProps) => {
  const triggerProcess = useTriggerProcess(processName)
  const [running, setRunning] = useState(false)
  const [_resultMessage, setResultMessage] = useState<string>()
  const [_errorMessage, setErrorMessage] = useState<string>()
  const client = useApiClient();

  const runProcessHandler = async () => {
    if (!client) return

    setRunning(true)
    try {
      const _payload = preparePayload ? await preparePayload() : payload
      await triggerProcess.mutateAsync(_payload)
      setResultMessage(resultMessage)

      onSuccess && onSuccess()

      setRunning(false)
    } catch (e) {
      setErrorMessage(errorMessage)
      setRunning(false)
    }

    setTimeout(() => {
      setResultMessage(undefined)
      setErrorMessage(undefined)
    }, 10000)
  }

  return <>
    <Button colorTheme={'success'}
            variation="primary"
            size='small'
            isLoading={running || isLoading} loadingText='Running'
            isDisabled={disabled}
            onClick={runProcessHandler}
    >
      Run process
    </Button>
    <View style={{marginTop: 10}}>
      {_resultMessage && <Alert variation="success" isDismissible={true} hasIcon={true}>{_resultMessage}</Alert>}
      {_errorMessage && <Alert variation="error" isDismissible={true} hasIcon={true}>{_errorMessage}</Alert>}
    </View>
  </>
}

